import { FC, useState } from "react";
import {
  Container,
  Typography,
  Button,
  Paper,
  Stack,
  styled,
  TextField,
  Grid,
  Alert,
} from "@mui/material";

import {
  useAddIntegrationMutation,
  useDeleteIntegrationMutation,
  useGetIntegrationDetailsQuery,
} from "../../generated";
import Heading from "../common/Heading";
import LoadingOverlay from "../common/LoadingOverlay";

interface State {
  slackChannelId: string;
  slackBotToken: string;
  sentinelOneUrl: string;
  sentinelOneToken: string;
  msTeamsWebHookUrl: string;
  googleChatWebHookUrl: string;
  jiraAuthorization: string;
  jiraRequestTypeId: string;
  jiraServiceDeskId: string;
  jiraDomain: string;
  jiraEmailAddress: string;
}

const SaveButton: any = styled(Button)({
  textTransform: "none",
  width: "141px",
  height: "56px",
});

const IntegrationsPage: FC = () => {
  const [values, setValues] = useState<State>({
    slackChannelId: "",
    slackBotToken: "",
    sentinelOneUrl: "",
    sentinelOneToken: "",
    msTeamsWebHookUrl: "",
    googleChatWebHookUrl: "",
    jiraAuthorization: "",
    jiraRequestTypeId: "",
    jiraServiceDeskId: "",
    jiraDomain: "",
    jiraEmailAddress: "",
  });

  const [alertMessage, setAlertMessage] = useState("");
  const [alertError, setAlertError] = useState("");
  const [operationLoading, setOperationLoading] = useState(false);

  const [addIntegrationMutation] = useAddIntegrationMutation();

  const [deleteIntegrationMutation] = useDeleteIntegrationMutation();

  const {
    data: integrationData,
    refetch,
    loading,
  } = useGetIntegrationDetailsQuery({
    variables: {},
    notifyOnNetworkStatusChange: true,
    onCompleted: (integrateData) => {
      if (!integrateData.getIntegrationDetails) {
        setAlertError("Error getting integration details!");
      }
    },
  });

  const handleSlackSaveOnClick = () => {
    setOperationLoading(true);
    addIntegrationMutation({
      variables: {
        application: "slack",
        app_value: values.slackBotToken,
        app_key: values.slackChannelId,
        app_endpoint: "",
      },
      onCompleted: () => {
        setAlertMessage("Successfully added slack integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error adding Slack integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleSentinelOneOnClick = () => {
    setOperationLoading(true);
    addIntegrationMutation({
      variables: {
        application: "sentinelone",
        app_value: values.sentinelOneToken,
        app_key: "",
        app_endpoint: values.sentinelOneUrl,
      },
      onCompleted: () => {
        setAlertMessage("Successfully added SentinelOne integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error adding SentinelOne integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleMsTeamsOnClick = () => {
    setOperationLoading(true);
    addIntegrationMutation({
      variables: {
        application: "ms_teams",
        app_value: "",
        app_key: "",
        app_endpoint: values.msTeamsWebHookUrl,
      },
      onCompleted: () => {
        setAlertMessage("Successfully added MS Teams integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error adding MS Teams integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleGoogleChatOnClick = () => {
    setOperationLoading(true);
    addIntegrationMutation({
      variables: {
        application: "google_chat",
        app_value: "",
        app_key: "",
        app_endpoint: values.googleChatWebHookUrl,
      },
      onCompleted: () => {
        setAlertMessage("Successfully added Google Chat integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error adding Google Chat integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleJiraOnClick = () => {
    setOperationLoading(true);
    addIntegrationMutation({
      variables: {
        application: "jira",
        app_value: JSON.stringify({
          "serviceDeskId": values.jiraServiceDeskId,
          "authorization": values.jiraAuthorization,
          "emailAddress": values.jiraEmailAddress,
        }),
        app_key: values.jiraRequestTypeId,
        app_endpoint: values.jiraDomain,
      },
      onCompleted: () => {
        setAlertMessage("Successfully added Jira integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error adding jira integration!");
        setOperationLoading(false);
      }
    })
  }

  const handleSlackDeleteOnClick = () => {
    setOperationLoading(true);
    deleteIntegrationMutation({
      variables: {
        application: "slack",
      },
      onCompleted: () => {
        setAlertMessage("Successfully deleted Slack integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error deleting Slack integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleSentinelOneDeleteOnClick = () => {
    setOperationLoading(true);
    deleteIntegrationMutation({
      variables: {
        application: "sentinelone",
      },
      onCompleted: () => {
        setAlertMessage("Successfully deleted SentinelOne integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error deleting SentinelOne integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleMsTeamsDeleteOnClick = () => {
    setOperationLoading(true);
    deleteIntegrationMutation({
      variables: {
        application: "ms_teams",
      },
      onCompleted: () => {
        setAlertMessage("Successfully deleted MS Teams integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error deleting MS Teams integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleGoogleChatDeleteOnClick = () => {
    setOperationLoading(true);
    deleteIntegrationMutation({
      variables: {
        application: "google_chat",
      },
      onCompleted: () => {
        setAlertMessage("Successfully deleted Google Chat integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error deleting Google Chat integration!");
        setOperationLoading(false);
      },
    });
  };

  const handleJiraDeleteOnClick = () => {
    setOperationLoading(true);
    deleteIntegrationMutation({
      variables: {
        application: "jira",
      },
      onCompleted: () => {
        setAlertMessage("Successfully deleted Jira integration!");
        refresh();
        setOperationLoading(false);
      },
      onError: () => {
        setAlertError("Error deleting Jira integration!");
        setOperationLoading(false);
      },
    });
  };

  const refresh = () => {
    refetch().then(() => {
      setValues({
        slackChannelId: "",
        slackBotToken: "",
        sentinelOneUrl: "",
        sentinelOneToken: "",
        msTeamsWebHookUrl: "",
        googleChatWebHookUrl: "",
        jiraAuthorization: "",
        jiraRequestTypeId: "",
        jiraServiceDeskId: "",
        jiraDomain: "",
        jiraEmailAddress: "",
      });
    });
  };

  return (
    <>
      <LoadingOverlay show={loading || operationLoading}></LoadingOverlay>
      <Container maxWidth="lg" sx={{ marginTop: "40px" }}>
        {alertMessage !== "" ? (
          <Alert severity="success" onClose={() => setAlertMessage("")}>
            {alertMessage}
          </Alert>
        ) : null}
        {alertError !== "" ? (
          <Alert severity="error" onClose={() => setAlertError("")}>
            {alertError}
          </Alert>
        ) : null}
        <Stack spacing={4} sx={{ mb: 3 }}>
          <Heading heading={"Integrations"} />
          <Typography
            variant="body1"
            align="left"
            sx={{ width: "1216px", height: "48px", fontWeight: 400 }}
            gutterBottom
          >
            {<p style={{ color: "red", display: "inline" }}>Note:</p>} Please
            note that due to deployment of additional AWS native security
            services as part of 6pillars' AUTOMATE+, you may notice an
            incremental increase in your AWS consumption costs.
          </Typography>
          <Paper sx={{ width: "1216px", height: "362px", padding: "32px" }}>
            <Grid container flexDirection="row">
              <Stack
                spacing={3}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  align="left"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Microsoft Teams
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  gutterBottom
                  sx={{ width: "600px", height: "72px", fontWeight: 400 }}
                >
                  To Integrate Microsoft Teams with AUTOMATE+ to receive security and compliance alerts, 
                  complete the steps in the documentation at {<a href="https://www.well-architected.ai/kb/teams-integration" target="_blank" rel="noopener noreferrer">knowledge-base</a>} then 
                  please enter the webhook URL and then click Save.
                </Typography>
                <Stack direction="row" spacing={4}>
                  <TextField
                    id="apiurl"
                    variant="outlined"
                    label="API URL"
                    onChange={(e) =>
                      setValues({
                        ...values,
                        msTeamsWebHookUrl: e.target.value,
                      })
                    }
                    value={values.msTeamsWebHookUrl}
                    placeholder={
                      integrationData?.getIntegrationDetails?.msTeamsDetails
                        ?.app_endpoint || ""
                    }
                    sx={{ width: "373.33px", height: "56px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
                {integrationData?.getIntegrationDetails?.msTeamsDetails
                  ?.app_endpoint ? (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleMsTeamsDeleteOnClick}
                  >
                    Delete
                  </SaveButton>
                ) : (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleMsTeamsOnClick}
                  >
                    Save
                  </SaveButton>
                )}
              </Stack>
              <Paper
                component="form"
                elevation={0}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src="/static/ms_teams_image.png"
                  alt="ms teams logo"
                  width="373.33px"
                  height="244px"
                />
              </Paper>
            </Grid>
          </Paper>
          <Paper sx={{ width: "1216px", height: "362px", padding: "32px" }}>
            <Grid container flexDirection="row">
              <Stack
                spacing={3}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  align="left"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Slack
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  gutterBottom
                  sx={{ width: "600px", height: "72px", fontWeight: 400 }}
                >
                  To integrate Slack with AUTOMATE+ to receive security and compliance alerts, 
                  complete the steps in the documentation at {<a href="https://www.well-architected.ai/kb/slack-integration" target="_blank" rel="noopener noreferrer">knowledge-base</a>} then 
                  please enter the Slack bot token and Slack Channel ID in the fields below, then hit Save.
                </Typography>
                <Stack direction="row" spacing={4}>
                  <TextField
                    id="slackchannelid"
                    variant="outlined"
                    onChange={(e) =>
                      setValues({ ...values, slackChannelId: e.target.value })
                    }
                    label="Slack Channel ID"
                    placeholder={
                      integrationData?.getIntegrationDetails?.slackDetails
                        ?.app_key || ""
                    }
                    value={values.slackChannelId}
                    sx={{ width: "373.33px", height: "56px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="slackbottoken"
                    variant="outlined"
                    label="Slack Bot Token"
                    placeholder={
                      integrationData?.getIntegrationDetails?.slackDetails
                        ?.app_value || ""
                    }
                    onChange={(e) =>
                      setValues({ ...values, slackBotToken: e.target.value })
                    }
                    value={values.slackBotToken}
                    sx={{ width: "341.33px", height: "56px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
                {integrationData?.getIntegrationDetails?.slackDetails
                  ?.app_value &&
                integrationData?.getIntegrationDetails?.slackDetails
                  ?.app_key ? (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleSlackDeleteOnClick}
                  >
                    Delete
                  </SaveButton>
                ) : (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleSlackSaveOnClick}
                  >
                    Save
                  </SaveButton>
                )}
              </Stack>
              <Paper
                component="form"
                elevation={0}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src="/static/slack_image.png"
                  alt="slack logo"
                  width="373.33px"
                  height="298px"
                />
              </Paper>
            </Grid>
          </Paper>
          <Paper sx={{ width: "1216px", height: "362px", padding: "32px" }}>
            <Grid container flexDirection="row">
              <Stack
                spacing={3}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  align="left"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Google Chat
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  gutterBottom
                  sx={{ width: "600px", height: "72px", fontWeight: 400 }}
                >
                  To integrate Google Chat with AUTOMATE+ to receive security and compliance alerts, 
                  complete the steps in the documentation at {<a href="https://www.well-architected.ai/kb/google-chat-integration" target="_blank" rel="noopener noreferrer">knowledge-base</a>} then 
                  please enter the webhook URL and then click Save.
                </Typography>
                <Stack direction="row" spacing={4}>
                  <TextField
                    id="apiurl"
                    variant="outlined"
                    label="API URL"
                    onChange={(e) =>
                      setValues({
                        ...values,
                        googleChatWebHookUrl: e.target.value,
                      })
                    }
                    value={values.googleChatWebHookUrl}
                    placeholder={
                      integrationData?.getIntegrationDetails?.googleChatDetails
                        ?.app_endpoint || ""
                    }
                    sx={{ width: "373.33px", height: "56px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
                {integrationData?.getIntegrationDetails?.googleChatDetails
                  ?.app_endpoint ? (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleGoogleChatDeleteOnClick}
                  >
                    Delete
                  </SaveButton>
                ) : (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleGoogleChatOnClick}
                  >
                    Save
                  </SaveButton>
                )}
              </Stack>
              <Paper
                component="form"
                elevation={0}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src="/static/google_chat_image.png"
                  alt="google chat logo"
                  width="373.33px"
                  height="244px"
                />
              </Paper>
            </Grid>
          </Paper>
          <Paper sx={{ width: "1216px", height: "308px", padding: "32px" }}>
            <Grid container flexDirection="row">
              <Stack
                spacing={3}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  align="left"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Sentinelone
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  gutterBottom
                  sx={{ height: "24px", fontWeight: 400 }}
                >
                  To integrate AUTOMATE+ with Sentinelone, please enter the API
                  url and Api Token.
                </Typography>
                <Stack direction="row" spacing={4}>
                  <TextField
                    id="apiurl"
                    variant="outlined"
                    label="API URL"
                    onChange={(e) =>
                      setValues({ ...values, sentinelOneUrl: e.target.value })
                    }
                    value={values.sentinelOneUrl}
                    placeholder={
                      integrationData?.getIntegrationDetails?.sentinelOneDetails
                        ?.app_endpoint || ""
                    }
                    sx={{ width: "373.33px", height: "56px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                  <TextField
                    id="apitokens1"
                    variant="outlined"
                    label="API Token"
                    onChange={(e) =>
                      setValues({ ...values, sentinelOneToken: e.target.value })
                    }
                    value={values.sentinelOneToken}
                    placeholder={
                      integrationData?.getIntegrationDetails?.sentinelOneDetails
                        ?.app_value || ""
                    }
                    sx={{ width: "341.33px", height: "72px" }}
                    InputLabelProps={{
                      shrink: true,
                    }}
                  />
                </Stack>
                {integrationData?.getIntegrationDetails?.sentinelOneDetails
                  ?.app_endpoint &&
                integrationData?.getIntegrationDetails?.sentinelOneDetails
                  ?.app_value ? (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleSentinelOneDeleteOnClick}
                  >
                    Delete
                  </SaveButton>
                ) : (
                  <SaveButton
                    color="secondary"
                    variant="contained"
                    onClick={handleSentinelOneOnClick}
                  >
                    Save
                  </SaveButton>
                )}
              </Stack>
              <Paper
                component="form"
                elevation={0}
                sx={{
                  display: "flex",
                  flexGrow: 1,
                  justifyContent: "flex-end",
                }}
              >
                <img
                  src="/static/sentinel_one_image.png"
                  alt="sentinel one logo"
                  width="373.33px"
                  height="244px"
                />
              </Paper>
            </Grid>
          </Paper>
          <Paper sx={{ width: "1216px", height: "520px", padding: "32px" }}>
            <Grid container flexDirection="row">
              <Stack
                spacing={3}
                sx={{ display: "flex", justifyContent: "flex-start" }}
              >
                <Typography
                  component="h6"
                  variant="h6"
                  align="left"
                  gutterBottom
                  sx={{ fontWeight: "bold" }}
                >
                  Jira
                </Typography>
                <Typography
                  variant="body1"
                  align="left"
                  gutterBottom
                  sx={{ height: "24px", fontWeight: 400 }}
                >
                  To integrate AUTOMATE+ with Jira, please enter the Authorization Token,
                  Request Type Id and Service Desk ID.
                </Typography>
                <Stack direction="row" spacing={4}>
                  <Stack direction="column" spacing={5}>
                      <TextField
                        id="authorizationToken"
                        variant="outlined"
                        label="Authorization Token"
                        onChange={(e) =>
                          setValues({ ...values, jiraAuthorization: e.target.value })
                        }
                        value={values.jiraAuthorization}
                        placeholder={
                          integrationData?.getIntegrationDetails?.jiraDetails
                            ?.app_value ? 
                              JSON.parse(integrationData?.getIntegrationDetails?.jiraDetails?.app_value).authorization
                              : ""
                        }
                        sx={{ width: "373.33px", height: "56px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="jiraDomain"
                        variant="outlined"
                        label="Jira domain"
                        onChange={(e) =>
                          setValues({ ...values, jiraDomain: e.target.value })
                        }
                        value={values.jiraDomain}
                        placeholder={
                          integrationData?.getIntegrationDetails?.jiraDetails
                            ?.app_endpoint || ""
                        }
                        sx={{ width: "373.33px", height: "56px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="jiraEmail"
                        variant="outlined"
                        label="Jira account email"
                        onChange={(e) =>
                          setValues({ ...values, jiraEmailAddress: e.target.value })
                        }
                        value={values.jiraEmailAddress}
                        placeholder={
                          integrationData?.getIntegrationDetails?.jiraDetails
                            ?.app_value ? 
                              JSON.parse(integrationData?.getIntegrationDetails?.jiraDetails?.app_value).emailAddress
                              : ""
                        }
                        sx={{ width: "373.33px", height: "72px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      {integrationData?.getIntegrationDetails?.jiraDetails
                      ?.app_endpoint &&
                    integrationData?.getIntegrationDetails?.jiraDetails
                      ?.app_value ? (
                      <SaveButton
                        color="secondary"
                        variant="contained"
                        onClick={handleJiraDeleteOnClick}
                      >
                        Delete
                      </SaveButton>
                    ) : (
                      <SaveButton
                        color="secondary"
                        variant="contained"
                        onClick={handleJiraOnClick}
                      >
                        Save
                      </SaveButton>
                    )}
                  </Stack>
                  <Stack direction="row" spacing={4}>
                    <Stack direction="column" spacing={2}>
                      <TextField
                        id="requestTypeId"
                        variant="outlined"
                        label="Request Type Id"
                        onChange={(e) =>
                          setValues({ ...values, jiraRequestTypeId: e.target.value })
                        }
                        value={values.jiraRequestTypeId}
                        placeholder={
                          integrationData?.getIntegrationDetails?.jiraDetails
                            ?.app_key || ""
                        }
                        sx={{ width: "341.33px", height: "72px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                      <TextField
                        id="serviceDeskId"
                        variant="outlined"
                        label="Service Desk ID"
                        onChange={(e) =>
                          setValues({ ...values, jiraServiceDeskId: e.target.value })
                        }
                        value={values.jiraServiceDeskId}
                        placeholder={
                          integrationData?.getIntegrationDetails?.jiraDetails
                            ?.app_value ? 
                              JSON.parse(integrationData?.getIntegrationDetails?.jiraDetails?.app_value).serviceDeskId
                              : ""
                        }
                        sx={{ width: "341.33px", height: "72px" }}
                        InputLabelProps={{
                          shrink: true,
                        }}
                      />
                    </Stack>
                    <img
                      src="/static/jira_image.png"
                      alt="Jira logo"
                      width="373.33px"
                      height="244px"
                    />
                  </Stack>
                </Stack>
              </Stack>
            </Grid>
          </Paper>
        </Stack>
      </Container>
    </>
  );
};

export default IntegrationsPage;
